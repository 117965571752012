<template>
  <div class="mb-4">
    <div class="container">
      <div class="row gutters-10">
        <div class="col-xl col-md-6">
          <div class="mb-3 mb-lg-0">
            <a href="http://shop.activeitzone.com/product/Girl-lol-Dress-Up-Kids-2019-summer-rose-Party-Dancing-casual-Dress-Girl-Christmas-Pageant-lol-Cosplay-Costume-girls-clothing-mXXag"
               class="d-block text-reset">
              <img
                  src="https://demo.activeitzone.com/ecommerce/public/uploads/all/UCXlw7rYzzLSxYOhWzIRDeV3OE19P6etegYAWPxY.jpg"
                  data-src="https://demo.activeitzone.com/ecommerce/public/uploads/all/UCXlw7rYzzLSxYOhWzIRDeV3OE19P6etegYAWPxY.jpg"
                  alt="Active eCommerce CMS promo" class="img-fluid lazyloaded">
            </a>
          </div>
        </div>
        <div class="col-xl col-md-6">
          <div class="mb-3 mb-lg-0">
            <a href="http://shop.activeitzone.com/search?category=Computer-Accessories" class="d-block text-reset">
              <img
                  src="https://demo.activeitzone.com/ecommerce/public/uploads/all/QrJSIxDRZEY6POuhlo8chyCdROdoWbeLqhQy1EwL.jpg"
                  data-src="https://demo.activeitzone.com/ecommerce/public/uploads/all/QrJSIxDRZEY6POuhlo8chyCdROdoWbeLqhQy1EwL.jpg"
                  alt="Active eCommerce CMS promo" class="img-fluid lazyloaded">
            </a>
          </div>
        </div>
        <div class="col-xl col-md-6">
          <div class="mb-3 mb-lg-0">
            <a href="http://shop.activeitzone.com/flash-deal/mega-flash-sale-vrcfk" class="d-block text-reset">
              <img
                  src="https://demo.activeitzone.com/ecommerce/public/uploads/all/JOktT5QrxPDSVKagzhTsgItxulc6OTXvX1zSxfi8.jpg"
                  data-src="https://demo.activeitzone.com/ecommerce/public/uploads/all/JOktT5QrxPDSVKagzhTsgItxulc6OTXvX1zSxfi8.jpg"
                  alt="Active eCommerce CMS promo" class="img-fluid lazyloaded">
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Advertisement"
}
</script>

<style scoped>

</style>
